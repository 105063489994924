import React from 'react';
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const MANIA_WARNING_RESULT = 'Concern'
const MANIA_HISTORY_RESULT = 'Positive'

const maniaAnswer = (questions, question_name) => (
  questions.filter(q => q.question_name === question_name).map(q => {
    const lowercaseAnswer = q.answer_text.toLowerCase()
    switch (lowercaseAnswer) {
    case 'you':
      return 'Parent'
    case "your spouse":
      return 'Other Parent'
    case "brothers/sisters":
      return "Adolescent's Brothers/Sisters"
    default:
      return q.answer_text
    }
  }).join(", ")
)

const ParentMania = ({ reportDetails, cmrs, mania }) => {
  // Here we detail all the copy added in the Alert Cards below. It's easier to see
  // the logic for determining the text, color and extra text here than it is in the
  // html structure

  var maniaColor = cmrs.includes(MANIA_WARNING_RESULT) ? 'red' : 'green'
  var maniaText = cmrs ? cmrs.split('.').join("") : null;
  if (!maniaText) {
    maniaText = 'N/A'
    maniaColor = 'grey'
  }

  const maniaHistoryIsPositive = mania.includes(MANIA_HISTORY_RESULT)
  var maniaHistoryColor = maniaHistoryIsPositive ? 'red' : 'green'
  
  var maniaHistoryText = null
  if (mania === "No answer given" || !mania) {
    maniaHistoryText = 'N/A'
    maniaHistoryColor = 'grey'
  }
  if (!maniaHistoryText && maniaHistoryIsPositive) maniaHistoryText = 'Positive'
  if (!maniaHistoryText && !maniaHistoryIsPositive) maniaHistoryText = 'None'

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="w-full sm:w-1/2">
        <p className="text-xs font-bold text-center pt-8 ">
          PARENT REPORT
        </p>
        <div className="mt-2">
          <p className="text-xl text-center font-bold text-sw-dark-blue">
            Mania Screen
          </p>
        </div>
        <div className="w-full mx-auto flex justify-center">
          <div className="w-3/4 md:w-1/2 mt-4 mb-8">
            <AlertCard
              color={maniaColor}
              text={maniaText}
            />
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2">
        <p className="text-xs font-bold text-center sm:pt-8">
          PARENT REPORT
        </p>
        <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
          Family History of Mania: {maniaAnswer(reportDetails.questions.guardian, "mania_history")}
        </p>
        <div className="w-full mx-auto flex justify-center">
          <div className="w-3/4 md:w-1/2 mt-4 mb-8">
            <AlertCard
              color={maniaHistoryColor}
              text={maniaHistoryText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParentMania
