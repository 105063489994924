import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const CAT_NEGATIVE_RESULT = 'Low or no risk'

const ChildCatSS = ({ cat, catDetails }) => {
  // Here we detail all the copy added in the Alert Cards below. It's easier to see
  // the logic for determining the text, color and extra text here than it is in the
  // html structure

  var catColor = 'grey'
  var catText = 'N/A'
  if (cat) {
    catColor = cat.includes(CAT_NEGATIVE_RESULT) ? 'green' : 'red'
    catText = cat.split('.').join("")
  }

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center">
        ADOLESCENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Suicidality (CAT-SS)
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={catColor}
            text={catText}
          />
        </div>
      </div>
      <DetailsQuestionTable questionType={catDetails} />
    </div>
  )
}

export default ChildCatSS
